import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getWalletTransactionHistory } from 'store/wallet/wallet.action';

export default function useWalletTransactionHistory() {
    const dispatch = useDispatch();
    const { history, historyError, historyIsLoading } = useSelector((state) => {
        const { history, historyError, historyIsLoading } = state.walletReducer;

        return { history, historyError, historyIsLoading };
    });

    // const [isFetched, setIsFetched] = useState(false);

    useEffect(() => {
        if (historyIsLoading) {
            return;
        }

        dispatch(getWalletTransactionHistory());
    }, []);

    // useEffect(() => {
    //     if (isEmpty(history) && !isFetched) {
    //         dispatch(getWalletTransactionHistory());
    //         setIsFetched(true);
    //     } else {
    //         setIsFetched(false);
    //     }
    // }, [history]);

    return { history, historyError, historyIsLoading };
}
