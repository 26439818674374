import moment from 'moment';

import houseRounded from 'assets/Images/house-rounded.png';
import Img from 'components/img/Img';
import { UnknownValue } from 'constants/defaults';

import styles from './index.module.scss';

function ProfileOrderHistoryDateItem({ date, transactionType = '' }) {
    return (
        <div className={styles.historyHeader}>
            <Img src={houseRounded} alt="house" />
            <p>
                {date && date !== UnknownValue ? moment(date).format('DD MMM. yyyy à hh:mm') : date}{' '}
                {transactionType ? `- ${ transactionType }` : ''}
            </p>
        </div>
    );
}

export default ProfileOrderHistoryDateItem;
