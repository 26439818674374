import React, { Fragment } from 'react';
import { useIntl } from 'react-intl';

import kfcLogo from 'assets/Images/kfc.png';
import NoResult from 'components/no-result';

import ProfileOrderHistoryDateItem from '../date-item';
import ProfileOrderHistoryTransactionsSearch from '../search';
import styles from './index.module.scss';
import ProfileOrderHistoryTransferItem from './transfer-item';

const MockedTransfers = [
    {
        date:   '26 nov. 2022 à 18:34',
        header: {
            image:       kfcLogo,
            title:       'Hayfa mzoughi',
            description: 'Happy birthday',
        },
        footer: {
            image:    kfcLogo,
            title:    'KFC gift card',
            price:    60,
            currency: 'DT',
        },
    },
    {
        date:   '27 nov. 2022 à 18:34',
        header: {
            image:       kfcLogo,
            title:       'Hayfa mzoughi',
            description: 'Happy birthday',
        },
        footer: {
            image:    kfcLogo,
            title:    'KFC gift card',
            price:    60,
            currency: 'DT',
        },
    },
    {
        date:   '28 nov. 2022 à 18:34',
        header: {
            image:       kfcLogo,
            title:       'Hayfa mzoughi',
            description: 'Happy birthday',
        },
        footer: {
            image:    kfcLogo,
            title:    'KFC gift card',
            price:    60,
            currency: 'DT',
        },
    },
    {
        date:   '29 nov. 2022 à 18:34',
        header: {
            image:       kfcLogo,
            title:       'Hayfa mzoughi',
            description: 'Happy birthday',
        },
        footer: {
            image:    kfcLogo,
            title:    'KFC gift card',
            price:    60,
            currency: 'DT',
        },
    },
];

function ProfileOrderHistoryTransfers() {
    const intl = useIntl();

    return (
        <section className={styles.container}>
            {/* <ProfileOrderHistoryTransactionsSearch placeholder="Search for a friend" /> */}

            <NoResult
                customStyle={{ marginTop: '1rem' }}
                message={intl.formatMessage({ id: 'profile.order-history.tab.transfer-history.no-results' })}
            />
            {/*
            <div className={styles.transferItems}>
                 {MockedTransfers.map((transfer, idx) => {
                    return (
                        <Fragment key={idx}>
                            <ProfileOrderHistoryDateItem date={transfer.date} />
                            <ProfileOrderHistoryTransferItem
                                headerData={transfer.header}
                                footerData={transfer.footer}
                            />
                        </Fragment>
                    );
                })}
            </div>
            */}
        </section>
    );
}

export default ProfileOrderHistoryTransfers;
