import { endpoints } from 'constants/api';
import TaooServerClient from 'http/axios';

import * as types from './wallet.types';

const api = endpoints.wallet;

export const getAllWalletItems = () => async function (dispatch) {
    dispatch({ type: types.WALLET_GET_ALL_REQUEST });

    try {
        const response = await TaooServerClient.get(endpoints.wallet.getWallet);

        if (response.status === 200) {
            const data = response?.data ?? [];

            dispatch(getAllWalletItemsSuccess(data));
        } else {
            dispatch(getAllWalletItemsError(response));
        }
    } catch (error) {
        dispatch(getAllWalletItemsError(error));
    }
};

function getAllWalletItemsSuccess(results) {
    return {
        type:   types.WALLET_GET_ALL_SUCCESS,
        wallet: results,
        error:  null,
    };
}

function getAllWalletItemsError(error) {
    return {
        type:   types.WALLET_GET_ALL_ERROR,
        wallet: null,
        error,
    };
}

export const getWalletTransactionHistory = () => async function (dispatch) {
    dispatch({ type: types.WALLET_GET_TRANSACTION_HISTORY_REQUEST });

    try {
        const response = await TaooServerClient.get(api.history);

        if (response.status === 200) {
            dispatch(getWalletTransactionHistorySuccess(response.data));
        } else {
            dispatch(getWalletTransactionHistoryError(response));
        }
    } catch (error) {
        dispatch(getWalletTransactionHistoryError(error));
    }
};

function getWalletTransactionHistorySuccess(results) {
    return {
        type:    types.WALLET_GET_TRANSACTION_HISTORY_SUCCESS,
        history: results,
        error:   null,
    };
}

function getWalletTransactionHistoryError(error) {
    return {
        type:    types.WALLET_GET_TRANSACTION_HISTORY_ERROR,
        history: null,
        error,
    };
}

export const getWalletExpired = () => async function (dispatch) {
    dispatch({ type: types.WALLET_GET_EXPIRED_REQUEST });

    try {
        const response = await TaooServerClient.get(api.getExpired);

        if (response.status === 200) {
            dispatch(getWalletExpiredSuccess(response.data));
        } else {
            dispatch(getWalletExpiredError(response));
        }
    } catch (error) {
        dispatch(getWalletExpiredError(error));
    }
};

function getWalletExpiredSuccess(results) {
    return {
        type:    types.WALLET_GET_EXPIRED_SUCCESS,
        expired: results,
        error:   null,
    };
}

function getWalletExpiredError(error) {
    return {
        type:    types.WALLET_GET_EXPIRED_ERROR,
        expired: null,
        error,
    };
}

export const getWalletItemHistory = (itemId) => async function (dispatch) {
    dispatch({ type: types.WALLET_GET_WALLET_ITEM_HISTORY_REQUEST });

    try {
        const response = await TaooServerClient.get(api.getSingleHistory(itemId));

        if (response.status === 200) {
            dispatch(getWalletItemHistorySuccess(response.data));
        } else {
            dispatch(getWalletItemHistoryError(response));
        }
    } catch (error) {
        dispatch(getWalletItemHistoryError(error));
    }
};

function getWalletItemHistorySuccess(results) {
    return {
        type:    types.WALLET_GET_WALLET_ITEM_HISTORY_SUCCESS,
        history: results,
        error:   null,
    };
}

function getWalletItemHistoryError(error) {
    return {
        type:    types.WALLET_GET_WALLET_ITEM_HISTORY_ERROR,
        history: null,
        error,
    };
}
