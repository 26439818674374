import { useIntl } from 'react-intl';

import PageTransitionWrapper from 'components/animation-wrapper/page-transition-wrapper';
import TAOOHelmet from 'components/helmet/Helmet';
import SectionNav from 'components/navigations/section-nav';

import styles from './index.module.scss';
import ProfileOrderHistoryTransactions from './transactions';
import ProfileOrderHistoryTransfers from './transfers';

export const OrderHistoryNavigationTabNodes = {
    1: <ProfileOrderHistoryTransactions />,
    2: <ProfileOrderHistoryTransfers />,
};

function ProfileOrderHistory() {
    const intl = useIntl();
    const OrderHistoryNavigationTabs = [
        {
            id:    1,
            label: intl.formatMessage({ id: 'profile.order-history.tab.transaction-history' }),
        },
        {
            id:    2,
            label: intl.formatMessage({ id: 'profile.order-history.tab.transfer-history' }),
        },
    ];

    return (
        <PageTransitionWrapper>
            <TAOOHelmet title={intl.formatMessage({ id: 'seo.page-title.order-history' })} />

            <div className={styles.container}>
                <SectionNav tabs={OrderHistoryNavigationTabs} tabNodes={OrderHistoryNavigationTabNodes} />
            </div>
        </PageTransitionWrapper>
    );
}

export default ProfileOrderHistory;
