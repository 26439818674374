import { Fragment, useMemo } from 'react';
import { useIntl } from 'react-intl';

import Loader from 'components/loaders/loader';
import NoResult from 'components/no-result';
import useWalletTransactionHistory from 'hooks/wallet/useWalletTransactionHistory';
import { generateImageUrl } from 'services/url';

import ProfileOrderHistoryDateItem from '../date-item';
import styles from './index.module.scss';
import ProfileOrderHistoryOrderItem from './order-item';

function ProfileOrderHistoryTransactions() {
    const intl = useIntl();
    const { history, historyIsLoading: isLoading } = useWalletTransactionHistory();

    const transactions = useMemo(() => formatData(history), [history]);

    return (
        <section className={styles.transactionsWrapper}>
            {/* <ProfileOrderHistoryTransactionsSearch placeholder={'Search for partners'} /> */}

            {isLoading && <Loader wrapperStyle={{ marginTop: '1rem' }} />}

            {transactions && transactions.length ? (
                <div className={styles.transactionItems}>
                    {transactions.map((transaction, idx) => (
                        <Fragment key={idx}>
                            <ProfileOrderHistoryDateItem date={transaction.date} transactionType={transaction?.type} />
                            <ProfileOrderHistoryOrderItem data={transaction.data} />
                        </Fragment>
                    ))}
                </div>
            ) : (
                !isLoading && (
                    <NoResult
                        customStyle={{ marginTop: '1rem' }}
                        message={intl.formatMessage({ id: 'profile.order-history.tab.transaction-history.no-results' })}
                    />
                )
            )}
        </section>
    );
}

const formatData = (data) => {
    if (!data) {
        return [];
    }

    return data.map((item) => ({
        date: item.createAt,

        // type: 'Achat en boutique',
        data: {
            image:               item?.company?.logo ? generateImageUrl(item?.company?.logo) : null,
            title:               item?.company?.name,
            totalAmount:         item?.totalAmount.price,
            totalAmountCurrency: item?.totalAmount.currency,
            totalAmountColor:    '#f0c514',
            hideTotalAmountSign: true,
            items:               [
                { title: 'Transaction status', description: item?.status.toLowerCase() },
                { title: 'Seller name', description: item?.company?.name },
                { title: 'Transaction Code', description: item?.id },
                {
                    title:       'Cash amount',
                    description: `${ item?.cashAmount?.price }${ item?.cashAmount?.currency }`,
                },
                ...(item.cards.length
                    ? item.cards.map((card) => ({
                        type:     'voucher',
                        image:    card?.companies[0]?.logo ? generateImageUrl(card.companies[0].logo) : null,
                        title:    card.title,
                        price:    -card.reducedAmount.price,
                        currency: card.reducedAmount.currency,
                    }))
                    : []),
            ],
        },
    }));
};

export default ProfileOrderHistoryTransactions;
