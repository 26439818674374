import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import PrimaryButton from 'components/form/button/PrimaryButton';
import Img from 'components/img/Img';
import { Alert, Polygon } from 'components/svg';
import { spring } from 'constants/index';
import { isUrl } from 'services/url';
import { neitherNullNorUndefined } from 'services/validation';

import styles from './index.module.scss';

const variants = {
    parent: {
        initial: { opacity: 0 },
        animate: { opacity: 1, transition: { staggerChildren: 0.05 } },
        exit:    { opacity: 0, transition: { duration: 0.2 } },
    },
    children: {
        initial: {
            y:       10,
            opacity: 0,
        },
        animate: {
            y:          0,
            opacity:    1,
            transition: {
                duration: 0.1,
            },
        },
    },
};

function ProfileOrderHistoryOrderItemHeader({ data }) {
    const [shouldShowWarningAlertTooltip, setShouldShowWarningAlertTooltip] = useState(false);

    const shouldShowImage = neitherNullNorUndefined(data.shouldShowImage) ? data.shouldShowImage : true;
    const hideSign = neitherNullNorUndefined(data?.hideTotalAmountSign) ? data?.hideTotalAmountSign : false;

    return (
        <div className={styles.headWrapper}>
            <article className={styles.head}>
                <div className="d-flex align-items-center">
                    {shouldShowImage && data.image && (
                        <div className={styles.headImage}>
                            <Img src={data.image} alt="company logo" />
                        </div>
                    )}
                    <h2 className={styles.title}>{data.title}</h2>
                </div>
                <h2
                    style={{ 
                        color: data?.totalAmountColor ?? '',
                        textDecoration: data?.totalAmountLineThrough ? 'line-through' : '' 
                    }}
                    className={`${ styles.points } ${ data?.totalAmount > 0 ? styles.success : styles.danger }`}
                >
                    {`${
                        data?.totalAmount >= 0 && !String(data?.totalAmount).includes('+') && !hideSign
                            ? `+${ data?.totalAmount }`
                            : data?.totalAmount
                    }${ data?.totalAmountCurrency }`}
                </h2>
                {data?.shouldShowWarning && (
                    <>
                        <Alert 
                            onClick={() => setShouldShowWarningAlertTooltip(prev => !prev)} 
                            className={styles.warningAlert} 
                            fill="rgb(186, 50, 50)" 
                        />
                        {shouldShowWarningAlertTooltip && (
                            <section className={styles.warningAlertTooltip}>
                                <p>
                                    <FormattedMessage id="points-history.header.warning-alert-tooltip-text"/>
                                </p>
                            </section>
                        )}
                    </>
                )}
            </article>
            <div className={`d-flex align-items-end ${ styles.tags }`}>
                {data?.tags && data.tags?.length
                    ? data.tags.slice(0, 3).map((tag, idx) => (
                        <p key={idx} className={`${ styles.tag } ${ tag.type ? styles[tag.type] : '' }`}>
                            {`${ tag.title }${ tag.description ? ` ${ tag.description }` : '' }`}
                        </p>
                    ))
                    : null}
            </div>
        </div>
    );
}

function ProfileOrderHistoryOrderItem({ data, defaultActiveStatus = false }) {
    const [isActive, setIsActive] = useState(defaultActiveStatus);

    return (
        <motion.div
            onClick={() => {
                if (!data?.shouldShowWarning) {
                    setIsActive((prev) => !prev);
                }
            }}
            layout
            transition={spring}
            className={styles.container}
        >
            <Polygon className={`${ styles.polygon } ${ styles.leftPolygon }`} />
            <Polygon className={`${ styles.polygon } ${ styles.rightPolygon }`} />

            <ProfileOrderHistoryOrderItemHeader data={data} />

            <AnimatePresence>
                {isActive && data?.items && (
                    <motion.div
                        variants={variants.parent}
                        initial="initial"
                        animate="animate"
                        className={styles.details}
                    >
                        {data?.shouldShowWarning ? (
                            <PrimaryButton
                                className={styles.warningButton}
                                disabled={data?.warningActionDisabled}
                                onClick={(e) => data?.warningActionCallback?.(e)}
                            >
                                {data?.warningActionText}
                            </PrimaryButton>
                        ) : (
                            data.items.map((item, idx) => item?.type && item?.type === 'voucher' ? (
                                <VoucherItem
                                    key={`voucher-item-${ idx }`}
                                    shouldShowImage={item?.shouldShowImage}
                                    image={item.image}
                                    title={item.title}
                                    price={item.price}
                                    currency={item.currency}
                                />
                            ) : (
                                <DetailItem
                                    key={`detail-item-${ idx }`}
                                    title={item.title}
                                    value={item?.description}
                                    isTitle={item?.isTitle}
                                    reverseFlex={item?.reverseFlex}
                                />
                            ))
                        )}
                    </motion.div>
                )}
            </AnimatePresence>
        </motion.div>
    );
}

function VoucherItem({
    image, title, price, currency, shouldShowImage = true,
}) {
    return (
        <motion.article variants={variants.children} className={`${ styles.head } ${ styles.voucherInTransaction }`}>
            <Polygon className={`${ styles.polygon } ${ styles.leftPolygon }`} />
            <Polygon className={`${ styles.polygon } ${ styles.rightPolygon }`} />

            <div className="d-flex align-items-center">
                {shouldShowImage && (
                    <div className={styles.logo}>{image && <Img src={image} alt="company logo" />}</div>
                )}
                <h2>{title}</h2>
            </div>
            <h2>{`${ price }${ currency }`}</h2>
        </motion.article>
    );
}

function DetailItem({
    title, value, isTitle = false, reverseFlex = false,
}) {
    const intl = useIntl();

    return (
        <motion.div
            className={`${ styles.detailsItem } ${ isTitle ? styles['--title'] : '' } ${
                reverseFlex ? styles['--reverseFlex'] : ''
            }`}
            variants={variants.children}
        >
            <p>{title}</p>
            {!isTitle &&
                value &&
                (isUrl(value) ? (
                    <a target="_blank" href={value} rel="noreferrer">
                        {intl.formatMessage({ id: 'points-history.validation-info.document.download' })}
                    </a>
                ) : (
                    <h4>{value}</h4>
                ))}
        </motion.div>
    );
}

export default ProfileOrderHistoryOrderItem;
